import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL || '';

const getProjects = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/projects`);

    if (response.status !== 200) {
      throw new Error('Failed to load projects');
    }

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getProjects;

